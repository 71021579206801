<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">农产品溯源解决方案</div>
          <div class="desc">利用区块链可追溯及不可篡改的特性，实现农产品的全程可追溯。使农产品生产、加工、运输、销售等所有环节的每一步都有记录，实现食用农产品“从农田到餐桌”全过程追溯管理。从而提高农产品的质量和安全性，增强消费者对农产品的信任度。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 业务痛点 -->
    <div class="section bg-white pain">
      <div class="title">业务痛点：信息孤岛、恶意窜货等诸多痛点</div>
      <div class="sub-title">传统农产品生产流转过程几乎处于封闭状态，信息的流通性低，恶性窜货行为时常发生，影响市场良性发展格局。假冒伪劣问题⼀直以来都是各行业亟待解决的痛点，高频率、达范围的农产品造假使得公众对于农产品溯源的诉求日益提⾼。如何能对农产品的生产与运输信息实现有效追溯成为了行业研究重点。</div>
      <div class="content">
        <div class="pain-list">
          <div class="item" v-for="item in painList" :key="item.name">
            <img class="icon" :src="getImage(item)" />
            <div class="title">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 建设思路 -->
    <div class="section ideas">
      <div class="title">建设思路</div>
      <div class="content">
        <div class="bg-sl"></div>
      </div>
    </div>

    <!-- 整体架构 -->
    <div class="section bg-white arc">
      <div class="title">整体架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 区块链溯源工作原理 -->
    <div class="section pri">
      <div class="title">区块链溯源工作原理</div>
      <div class="sub-title">利用供应链追溯/防伪平台，通过“⼀物⼀码，码物同追”模式，将农产品的信息存入区块链网络，并且通过QRCode、RFID或NFC标签等数字标签方式在区块链上进行数据存储。结合现有的前端实现，消费者通过微信、小程序、支付宝等多种方式扫描农产品二维码实现信息查询。</div>
      <div class="content">
        <div class="bg-pri"></div>
      </div>
    </div>

    <!-- 溯源应用标准流程 -->
    <div class="section bg-white flow">
      <div class="title">溯源应用标准流程</div>
      <div class="sub-title">从育秧、备耕开始，到种植管理、收获、加工、储运、销售，配上物联网监测技术，实时监控及记录人参的环境参数信息，例如土壤信息，水质信息等。并实时记录种植过程中投入品的使用，例如肥料使用信息等。收获及加工环节的检验信息、物流环节的物流信息以及销售订单等信息全部记录在数据库中并写入区块链。</div>
      <div class="content">
        <div class="bg-flow"></div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      painList: [
        {
          name: '信息孤岛',
          icon: 'icon-pain-1.png'
        },
        {
          name: '信息篡改难杜绝',
          icon: 'icon-pain-2.png'
        },
        {
          name: '中心化存储',
          icon: 'icon-pain-3.png'
        },
        {
          name: '信息流通性差',
          icon: 'icon-pain-4.png'
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/agr-prod/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
